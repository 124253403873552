import './fakepage.css';
import React from "react";

export function Fakepage({text}){
    return(
        <body>
        <header>
            <h1>CogBlog – A Cognitive Psychology Blog</h1>
            <nav>
                <ul>
                    <li><a href="https://www.google.com">Home</a></li>
                    <li><a href="https://www.google.com">World</a></li>
                    <li><a href="https://www.google.com">Tech</a></li>
                    <li><a href="https://www.google.com">Culture</a></li>
                    <li><a href="https://www.google.com">Politics</a></li>
                </ul>
            </nav>
        </header>

        <main>
            <article>
                <header>
                    <h2>Are the Stereotypes True: The Effects of Divided Attention Declines in Older Adults on Every Day Activities</h2>
                    <p className="byline">11/14/2022, Berna Forbes</p>
                </header>
                <section>
                    <p>
                        Have you ever seen a meme or a video that depicts older adults not being able to drive, not being able to walk, not being able to remember things, or not being able to focus. These types of media are very present in our lives today, and are reflective of many of the stereotypes that surround older adults in our society today. But, have you ever taken a second to think about why these stereotypes exist, and why we are so quick to believe them? In other words, are there actually age-related changes or declines that older adults are experiencing that impact their ability to walk, drive, remember, or perform any other daily function, or is it just stereotyping of older adults that create these perceptions in our minds about how older adults perform on these tasks?
                    </p>
                    <p>
                        Research about cognitive aging has provided us with insight into how our brain and cognitive functioning changes as a result of getting older, and how this in turn influences performance on a daily basis as we age. The field of cognitive aging is very wide, and research encompasses a wide variety of topics, including memory, perception, attention, language, prior knowledge, and much more. Additionally, a wide variety of theories have been carefully developed and researched to provide explanations for the declines that we see in older adults as they age.
                    </p>
                    <p>{text}</p>
                </section>
            </article>
        </main>

        <footer>
            <p>© 2024 CogBlog – A Cognitive Psychology Blog</p>
        </footer>
        </body>
    );
}