import React, { useState } from 'react';
import './App.css';
import {Fakepage} from "./fakepage";

function Search() {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    const handleQuery = async () => {
        if (!input.trim()) return;
        const data = {
            message: input
        };
        try {
            const response = await fetch('https://fakegptbackend.allonsyjia.workers.dev/api/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setResponse(result.reply.content);
            setInput('');
            console.log(response)
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    };


    return (
        <>
        {response === '' ? (
            <>
        <div className="google-title">
            <span className="blue">G</span>
            <span className="red">O</span>
            <span className="yellow">O</span>
            <span className="blue">G</span>
            <span className="green">L</span>
            <span className="red">E</span>
        </div>
        <div className={'hero'}>
                <div className={'searchBox'}>
                    <img src={"/Img/tumi.png"} id={"google-img"} alt="logo"/>
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleQuery()}
                        id={"input"}
                    />
                    <button onClick={handleQuery} id={"button"}>
                        <img src="/Img/mic.png" alt="mic" id="mic"/>
                    </button>
                </div>
        </div>
            </>
            ) : (
            <div>
            <Fakepage text={response}/>
            </div>)}
        </>
    );
}

export default Search;
